import styled from '@emotion/styled';
import {
  Callout,
  EmptyValue,
  FlexGroupWrapper,
  FlexItem,
  Icon,
  JobStatusBadge,
  Loading,
  Table,
  Tooltip,
} from '@tecton/ComponentRedesign';
import { TectonDateTimeFormatWithTz } from '@tecton/ComponentRedesign/utils';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Job, MaterializationAttempt, MaterializationStatusState } from '../../../api/gql/graphql';
import { useTimezoneSettings } from '../../../components/context/TimezoneContext';
import IdUtils from '../../../utils/id-utils';
import { FeatureFlags, useUserSettings } from '../../context/UserSettingsContext';
import { durationFromSeconds, getJobStatus } from './JobsUtils';
import { JobStatusInput, JobStatusValue } from './types';

import { ReactComponent as InformationIcon } from '@svg/information.svg';

export interface JobsRowExpansionProps {
  job: Job;
  wrapperRef: React.RefObject<HTMLDivElement>;
}

const Wrapper = styled.div<{ wrapperRef: React.RefObject<HTMLDivElement> }>`
  background: ${(props) => props.theme.colors.body};
  padding: ${(props) => props.theme.padding.l};
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.padding.l};
  align-self: stretch;

  width: ${(props) => props.wrapperRef.current?.getBoundingClientRect().width}px;

  td {
    padding: ${(props) => props.theme.padding.l} !important;
  }
`;

const RowExpansionHeading = styled.div`
  color: ${(props) => props.theme.colors.title};
  font-weight: ${(props) => props.theme.font.weight.semiBold};
  line-height: 20px; /* 142.857% */
`;

const RowExpansionInteriorTable = styled.div`
  background-color: ${(props) => props.theme.colors.emptyShade};
`;
const JobsRowExpansion = ({ job, wrapperRef }: JobsRowExpansionProps) => {
  const { featureEnabled, canShowComputeIdentity } = useUserSettings();
  const { timezone } = useTimezoneSettings();
  const canShowConsumption = featureEnabled(FeatureFlags.INTERNAL_DEBUG_SHOW_CONSUMPTION_INFO);
  const items = job.attempts;

  const columns = [
    {
      name: 'Status',
      render: (attempt: MaterializationAttempt) => {
        const asStatusObject = getJobStatus(attempt?.materializationState as JobStatusInput) as JobStatusValue;

        if (!asStatusObject) {
          return <EmptyValue />;
        }
        return <JobStatusBadge status={asStatusObject.value as MaterializationStatusState} />;
      },
    },
    {
      name: 'Attempt Created At',
      render: (attempt: MaterializationAttempt) =>
        attempt.attemptCreatedAt ? TectonDateTimeFormatWithTz(moment(attempt.attemptCreatedAt), timezone) : 'n/a',
    },
    {
      name: 'Duration',
      render: (attempt: MaterializationAttempt) => {
        if (!attempt?.duration) return <></>;

        const asStatusObject = getJobStatus(attempt?.materializationState as JobStatusInput) as JobStatusValue;

        return (
          <>
            <FlexGroupWrapper alignItems="center" gap="s" direction="row">
              <FlexItem grow={false}>{durationFromSeconds(parseFloat(attempt?.duration as string))}</FlexItem>
              {asStatusObject.value === MaterializationStatusState.MaterializationStatusStateRunning ? (
                <FlexItem grow={false}>
                  <Tooltip
                    content="Streaming jobs are continuously running. This duration field only updates upon refresh of the page. "
                    trigger={<Loading size="m" />}
                  />
                </FlexItem>
              ) : null}
            </FlexGroupWrapper>
          </>
        );
      },
    },
    ...(canShowConsumption
      ? [
          {
            name: 'Rows Written',
            field: 'rows',
            render: (_: any, row: MaterializationAttempt) => {
              const online = row?.writeToOnlineFeatureStore ? (
                row?.attemptConsumption?.onlineRowsWritten ? (
                  parseInt(row.attemptConsumption.onlineRowsWritten).toLocaleString('en-US')
                ) : (
                  '-'
                )
              ) : (
                <EmptyValue />
              );
              const offline = row?.writeToOfflineFeatureStore ? (
                row?.attemptConsumption?.offlineRowsWritten ? (
                  parseInt(row.attemptConsumption.offlineRowsWritten).toLocaleString('en-US')
                ) : (
                  '-'
                )
              ) : (
                <EmptyValue />
              );
              return (
                <>
                  online: {online} &nbsp;/ offline: {offline}
                </>
              );
            },
          },
          {
            name: 'Billed Duration',
            field: 'rows',
            render: (_: any, row: MaterializationAttempt) => {
              const duration = row?.attemptConsumption?.jobDuration ? (
                durationFromSeconds(parseFloat(row?.attemptConsumption?.jobDuration))
              ) : (
                <EmptyValue />
              );
              return <>{duration}</>;
            },
          },
          {
            name: 'Job ID',
            field: 'rows',
            render: (_: any, row: MaterializationAttempt) => {
              const jobId =
                row.materializationState === 'scheduled' ? row?.materializationTaskId : row?.materializationAttemptId;
              return <>{IdUtils.toStringId(jobId)}</>;
            },
          },
        ]
      : []),
    ...(canShowComputeIdentity
      ? [
          {
            name: 'Compute Identity',
            field: 'rows',
            render: (_: any, row: MaterializationAttempt) => {
              if (job?.fvType?.value !== 'BATCH') return <EmptyValue />;
              return <>{row?.computeIdentity || 'not set'}</>;
            },
          },
        ]
      : []),
    {
      name: 'Notes',
      render: (row: MaterializationAttempt) => {
        const jobPlanId = IdUtils.toStringId(job.planId);
        return (
          <>
            {job.planId ? (
              <Link to={`/repo/${job.workspace}/home/apply-log/${jobPlanId}`} target="_blank">
                View Plan Summary
              </Link>
            ) : (
              <>
                {/* Fall back for now until planId is done by backend. */}
                {row.spotInstanceFailure && `Spot Instance Failure;`} {row.terminationReason}
              </>
            )}
          </>
        );
      },
    },
    {
      name: 'Details',
      render: (row: MaterializationAttempt) => {
        if (!row.runPageUrl && !row?.tectonManagedAttemptId) return <>Not Available</>;

        if (row?.tectonManagedAttemptId) {
          return (
            <Link to={`/jobs/${row.tectonManagedAttemptId}`} target="_blank">
              View job detail
            </Link>
          );
        }

        return (
          <Link to={row?.runPageUrl || ''} target="_blank">
            <Icon type={InformationIcon} /> View Info
          </Link>
        );
      },
    },
  ];

  return (
    <Wrapper wrapperRef={wrapperRef}>
      {job.scheduleErrorMessage && (
        <Callout title={'Scheduling Details'}>
          <p>{job.scheduleErrorMessage}</p>
        </Callout>
      )}
      <RowExpansionHeading>{items?.length ?? 0} Job Attempt</RowExpansionHeading>
      <RowExpansionInteriorTable>
        <Table items={items as MaterializationAttempt[]} columns={columns} compressed={false} />
      </RowExpansionInteriorTable>
    </Wrapper>
  );
};

export default JobsRowExpansion;
